<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="make">Make</label>
            <v-select
              name="make"
              v-model="carDetail.make"
              :reduce="(make) => make.make"
              class="w-full"
              :options="makers"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Makers"
              :searchable="true"
              label="make"
              @search="fetchMakeOptions"
              @input="onInputChanged('make')"
              v-validate="'required'"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('make')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span class="text-danger text-sm">{{ errors.first('make') }}</span>
        </div>
        
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="model">Model</label>
            <v-select
              name="model"
              v-model="carDetail.model"
              :reduce="(family) => family.family"
              class="w-full"
              :options="models"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Model"
              :searchable="true"
              label="family"
              @search="fetchModelOptions"
              @input="onInputChanged('model')"
              v-validate="'required'"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('model')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span class="text-danger text-sm">{{ errors.first('model') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="year">Year</label>
            <v-select
              name="year"
              v-model="carDetail.year"
              :reduce="(year) => year.year"
              class="w-full"
              :options="years"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Year"
              :searchable="true"
              label="year"
              @search="fetchYearOptions"
              @input="onInputChanged('year')"
              v-validate="'required'"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('year')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span class="text-danger text-sm">{{ errors.first('year') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="badge">Badge</label>
            <v-select
              name="carMake"
              v-model="carDetail.badge"
              :reduce="(badge) => badge.badge"
              class="w-full"
              :options="badges"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Badge"
              :searchable="true"
              label="badge"
              @search="fetchBadgeOptions"
              @input="onInputChanged('badge')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('badge')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="series">Series</label>
            <v-select
              name="carMake"
              v-model="carDetail.series"
              :reduce="(series) => series.series"
              class="w-full"
              :options="seriesList"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Series"
              :searchable="true"
              label="series"
              @search="fetchSeriesOptions"
              @input="onInputChanged('series')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('series')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="bodyType">Body Type</label>
            <v-select
              name="carBody"
              v-model="carDetail.bodyType"
              :reduce="(bodyType) => bodyType.bodyType"
              class="w-full"
              :options="bodyTypes"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Body"
              :searchable="true"
              label="bodyType"
              @search="fetchBodyTypeOptions"
              @input="onInputChanged('bodyType')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('bodyType')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualBodyTypeError" style="text-align:center;" class="text-danger text-sm">{{manualBodyTypeError}}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="transmission">Transmission</label>
            <v-select
              name="carTransmission"
              v-model="carDetail.transmission"
              :reduce="(transmission) => transmission.transmission"
              class="w-full"
              :options="transmissions"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Transmission"
              :searchable="true"
              label="transmission"
              @search="fetchTransmissionOptions"
              @input="onInputChanged('transmission')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('transmission')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualTransmissionError" style="text-align:center;" class="text-danger text-sm">{{manualTransmissionError}}</span>
        </div>
      </vs-col>

       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="engineCapacity">Engine Capacity</label>
            <v-select
              name="carEngine"
              v-model="carDetail.engineCapacity"
              :reduce="(engineCapacity) => engineCapacity.engineCapacity"
              class="w-full"
              :options="engineCapacities"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Engine"
              :searchable="true"
              label="engineCapacity"
              @search="fetchEngineCapacityOptions"
              @input="onInputChanged('engineCapacity')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('engineCapacity')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualEngineCapacityError" style="text-align:center;" class="text-danger text-sm">{{manualEngineCapacityError}}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cylinder">Cylinder</label>
            <v-select
              name="carCylinder"
              v-model="carDetail.cylinder"
              :reduce="(cylinder) => cylinder.cylinder"
              class="w-full"
              :options="cylinders"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Cylinder"
              :searchable="true"
              label="cylinder"
              @search="fetchCylinderOptions"
              @input="onInputChanged('cylinder')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('cylinder')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualCylinderError" style="text-align:center;" class="text-danger text-sm">{{manualCylinderError}}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationState">Registration State</label>
            <v-select
              name="state"
              v-model="carDetail.registrationState"
              :reduce="(stateName) => stateName.stateName"
              class="w-full"
              :options="states"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Registration State"
              :searchable="true"
              label="stateName"
              v-validate="'required'"
            >
            </v-select>
            <span class="text-danger text-sm">{{ errors.first('state') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="kilometer">Kilometers</label>
           <vs-input
              name="kilometer"
              v-validate="'required|max:20'"
              data-vv-validate-on="blur"
              maxlength="20"
              type="number"
              v-model="carDetail.kilometer"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first('kilometer') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="askingPrice">Asking Price</label>
            <vs-input
              name="askingPrice"
              type="number"
              v-validate="'required|max:20'"
              data-vv-validate-on="blur"
              maxlength="20"
              v-model="carDetail.askingPrice"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first('askingPrice') }}</span>
        </div>
      </vs-col>
      
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" v-if="carDetail._id">
        <div class="w-full m-5">
          <label for="driveType">Drive Type</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'max:50'"
            name="driveType"
            maxlength="50"
            v-model="carDetail.drv"
            disabled
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('driveType') }}</span>
        </div>
      </vs-col>
           
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="interiorColor">Interior Color</label>
          <vs-input
            name="interiorColor"
            v-validate="'required|max:50'"
            data-vv-validate-on="blur"
            maxlength="20"
            v-model="carDetail.interiorColor"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('interiorColor') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="exteriorColor">Exterior Color</label>
          <vs-input
            name="exteriorColor"
            v-validate="'required|max:50'"
            data-vv-validate-on="blur"
            v-model="carDetail.exteriorColor"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('exteriorColor') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="vinNumber">VIN Number</label>
          <vs-input
            v-validate=""
            data-vv-validate-on="blur"
            name="vinNumber"
            v-model="carDetail.vinNumber"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('vinNumber') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationExpiryDate">Registration Expiry Date</label>
         <flat-pickr
            v-model="carDetail.registrationExpiryDate"
            class="w-full"
            name="registrationExpiryDate"
          />
          <span class="text-danger text-sm">{{ errors.first('registrationExpiryDate') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="licensePlate">License Plate</label>
          <vs-input
            v-validate="'min:3'"
            data-vv-validate-on="blur"
            name="licensePlate"
            v-model="carDetail.licencePlate"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('licensePlate') }}</span>
        </div>
      </vs-col>

       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8">
        <div class="w-full m-5">
          <label for="description">Description</label>
          <vs-textarea
            name="description"
            v-model="carDetail.description"
            v-validate="'required|min:5'"
            data-vv-validate-on="blur"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('description') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationUptoDate">Registration Upto Date?</label>
          <vs-checkbox v-model="carDetail.isRegistrationUptoDate"></vs-checkbox>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="hasWrittenOff">Has Written Off?</label>
          <vs-checkbox v-model="carDetail.hasWrittenOff"></vs-checkbox>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="fullServiceAvailable">Full Service Available?</label>
          <vs-checkbox v-model="carDetail.fullServiceAvailable"></vs-checkbox>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="isDraft">Is Draft?</label>
          <vs-checkbox v-model="carDetail.isDrafted"></vs-checkbox>
        </div>
      </vs-col>
    </vs-row>

      <vs-row class="mt-3" :key="imageComponentKey" style="margin-top:20px;">
        <!-- <div class="fileContainer"> -->
            <!-- <vs-image
              :src="photo.imageUrl"
              style="height:200px;width:200px;object-fit:contain;"
            /> -->
            <!-- <div class="imageContainer">
              <div class="image" v-for="(photo,index) in oldPhotos" :key="index">
              <img :src="photo.imageUrl" style="object-fit:contain;"/>
              <vs-icon @click="removeImageFromOldPhotos(photo, index)" class="btn" icon="highlight_off"></vs-icon>
            </div>
            
            </div>
            
        </div>                 -->
        <vs-col class="fileContainer" v-for="(photo,index) in oldPhotos" :key="index" vs-w="3">
            <img class="image" :src="photo.imageUrl" width="200px" style="object-fit:contain;"/>
            <vs-icon @click="removeImageFromOldPhotos(photo, index)" class="btn" icon="highlight_off"></vs-icon>
        </vs-col>  
    </vs-row>
     
    <vs-row>
      <vs-col>
        <div
        id="my-strictly-unique-vue-upload-multiple-image"
        style="display: flex; justify-content: center; margin-top: 30px;"
      >
        <vue-upload-multiple-image
          @upload-success="uploadImageSuccess"
          @before-remove="beforeRemove"
          :showEdit="false"
          :dataImages="dataPhotos"
          browseText="Upload Car Images"
          dragText
          :showPrimary="false"
          :maxImage="maxImageNumber"
        ></vue-upload-multiple-image>
        
      </div>
      <span v-if="imageUploadError" style="text-align:center;" class="text-danger text-sm">{{
        imageUploadError
      }}</span>
      </vs-col>
    </vs-row>
      <vs-row vs-justify="flex-end">
        <vs-button color="success" v-if="carDetail._id" @click="updateCarDetail">Update</vs-button>
        <!-- <vs-button color="success" v-if="!carDetail._id" @click="addNewCarDetail">Save</vs-button> -->
        <vs-button
          color="danger"
          style="margin-left:5px;"
          @click="cancelFunc"
        >Cancel</vs-button>
      </vs-row>

      <car-data-manual-modal v-if="openManualCarDataModel" :attribute="carAttributeType" @prop-car-update="closeCarManualModal"/>
  </vx-card>
</template>

  <script>
import { mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "../../http/axios";
import vSelect from "vue-select";
import carDataManualModal from "./carDataManualModal";

export default {
  name: "carDetails",
  components: {
    flatPickr,
    VueUploadMultipleImage,
    "v-select": vSelect,
    "car-data-manual-modal": carDataManualModal,
  },

  data: () => ({
    activeTab: 0,
    dealerId: "",
    carId: "",
    carDetail: {
      licensePlate: "",
      make: "",
      model: "",
      transmission: "",
      bodyType: "",
      isDrafted: false,
      interiorColor: "",
      exteriorColor: "",
      year: "",
      askingPrice: 0,
      description: "",
      kilometer: 0,
      series: "",
      badge: "",
      engineCapacity: "",
      cylinder: "",
      vinNumber: "",
      registrationState: "",
      isRegistrationUptoDate: false,
      registrationExpiryDate: "",
      hasWrittenOff: false,
      fullServiceAvailable: false,
      images: [],
    },
    photos: [],
    dataPhotos: [],
    dataImg: "",
    imageUploadError: "",
    states: [],
    makers: [],
    models: [],
    years: [],
    badges: [],
    seriesList: [],
    bodyTypes: [],
    transmissions: [],
    engineCapacities: [], 
    cylinders: [], 
    kilometers: [],
    prices: [],
    timeout: null,
    carAttributesParams : {
      after: "",
      page: 1,
      limit: 50,
      search: "",
      make: "",
      model: "",
      year: "",
      badge: "",
      series: "",
      bodyType: "",
      transmission: "",
      engineCapacity: "",
      cylinder: "",
    },
    imageComponentKey:0,
    oldPhotos: [],
    openManualCarDataModel: false,
    carAttributeType: "",
    isMakeManual: false,
    isModelManual: false,
    isYearManual: false,
    isBadgeManual: false,
    isSeriesManual: false,
    isBodyTypeManual: false,
    isTransmissionManual: false,
    isEngineCapacityManual: false,
    isCylinderManual: false,
    manualBodyTypeError: "",
    manualTransmissionError: "",
    manualEngineCapacityError: "",
    manualCylinderError: "",
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any()
      );
    },
    maxImageNumber() {
      return 30 - this.oldPhotos.length;
    },
    carDetailMake() {
      if(!this.carDetail.make) return "";
      return this.carDetail.make;
    },
    carDetailModel() {
      if(!this.carDetail.model) return "";
      return this.carDetail.model;
    },
    carDetailYear() {
      if(!this.carDetail.year) return "";
      return this.carDetail.year;
    },
    carDetailBadge() {
      if(!this.carDetail.badge) return "";
      return this.carDetail.badge;
    },
    carDetailSeries() {
      if(!this.carDetail.series) return "";
      return this.carDetail.series;
    },
    carDetailBodyType() {
      if(!this.carDetail.bodyType) return "";
      return this.carDetail.bodyType;
    },
    carDetailTransmission() {
      if(!this.carDetail.transmission) return "";
      return this.carDetail.transmission;
    },
    carDetailEngineCapacity() {
      if(!this.carDetail.engineCapacity) return "";
      return this.carDetail.engineCapacity;
    },
    carDetailCylinder() {
      if(!this.carDetail.cylinder) return "";
      return this.carDetail.cylinder;
    },
    checkForManual() {
      return (
        !this.carDetail.makeManual && !this.carDetail.modelManual && !this.carDetail.yearManual && !this.carDetail.badgeManual && !this.carDetail.seriesManual
      )
    },
    isCarFieldManual() {
      if(this.checkForManual && !this.carDetail.bodyType && !this.carDetail.transmission && !this.carDetail.engineCapacity && !this.carDetail.cylinder) {
        return false;
      }
      else if(this.checkForManual && !this.carDetail.bodyTypeManual && !this.carDetail.transmissionManual && !this.carDetail.engineCapacityManual && !this.carDetail.cylinderManual) {
        return false;
      }
      return (
        this.carDetail.makeManual || this.carDetail.modelManual || this.carDetail.yearManual || this.carDetail.badgeManual || this.carDetail.seriesManual || this.carDetail.bodyTypeManual || this.carDetail.transmissionManual || this.carDetail.engineCapacityManual || this.carDetail.cylinderManual
      )
    },
    
  },
  methods: {
    ...mapActions("carDealer", ["updateDealerCar", "addNewCar", "carMakerList", "carModels", "carYearList", "carBadgeList", "carSeriesList", "carBodyTypeList", "carTransmissionList", "carEngineCapacity", "carCylinders", "statesList", "priceRange", "kilometerRange"]),
    ...mapActions("admin", ["carDetails"]),

    clearValue(){
      console.log('value cleared')
    },
    onInputChanged(type) {
      switch(type) {
        case "make":
          // this.isMakeManual = false;
          this.carDetail.makeManual = false;
          break;
        case "model":
          // this.isModelManual = false;
          this.carDetail.modelManual = false;
          break;
        case "year":
          // this.isYearManual = false;
          this.carDetail.yearManual = false;
          break;
        case "badge":
          // this.isBadgeManual = false;
          this.carDetail.badgeManual = false;
          break;
        case "series":
          // this.isSeriesManual = false;
          this.carDetail.seriesManual = false;
          break;
        case "bodyType":
          // this.isBodyTypeManual = this.isCarFieldManual ? true : false;
          this.carDetail.bodyTypeManual = this.isCarFieldManual ? true : false;
          this.manualBodyTypeError = "";
          break;
        case "transmission":
          // this.isTransmissionManual = this.isCarFieldManual ? true : false;
          this.carDetail.transmissionManual = this.isCarFieldManual ? true : false;
          this.manualTransmissionError = "";
          break;
        case "engineCapacity":
          // this.isEngineCapacityManual = this.isCarFieldManual ? true : false;
          this.carDetail.engineCapacityManual = this.isCarFieldManual ? true : false;
          this.manualEngineCapacityError = "";
          break;
        case "cylinder":
          // this.isCylinderManual = this.isCarFieldManual ? true : false;
          this.carDetail.cylinderManual = this.isCarFieldManual ? true : false;
          this.manualCylinderError = "";
          break;
        }
    },

    openManualEntryModal(attribute){
      this.carAttributeType = attribute;
      this.openManualCarDataModel = true;
    },
    closeCarManualModal(value) {
      this.openManualCarDataModel = false;
      if(value) {
        let carKey = Object.keys(value)[0]
        switch(carKey) {
          case "make":
            this.carDetail.make = value[carKey];
            this.carAttributesParams.make = value[carKey];
            this.isMakeManual = true;
            this.carDetail.makeManual = true;
            break;
          case "model":
            this.carDetail.model = value[carKey];
            this.carAttributesParams.model = value[carKey];
            this.isModelManual = true;
            this.carDetail.modelManual = true;
            break;
          case "year":
            this.carDetail.year = value[carKey];
            this.carAttributesParams.year = value[carKey];
            this.isYearManual = true;
            this.carDetail.yearManual = true;
            break;
          case "badge":
            this.carDetail.badge = value[carKey];
            this.carAttributesParams.badge = value[carKey];
            this.isBadgeManual = true;
            this.carDetail.badgeManual = true;
            break;
          case "series":
            this.carDetail.series = value[carKey];
            this.carAttributesParams.series = value[carKey];
            this.isSeriesManual = true;
            this.carDetail.seriesManual = true;
            break;
          case "bodyType":
            this.carDetail.bodyType = value[carKey];
            this.carAttributesParams.bodyType = value[carKey];
            this.isBodyTypeManual = true;
            this.carDetail.bodyTypeManual = true;
            break;
          case "transmission":
            this.carDetail.transmission = value[carKey];
            this.carAttributesParams.transmission = value[carKey];
            this.isTransmissionManual = true;
            this.carDetail.transmissionManual = true;
            break;
          case "engineCapacity":
            this.carDetail.engineCapacity = value[carKey];
            this.carAttributesParams.engineCapacity = value[carKey];
            this.isEngineCapacityManual = true;
            this.carDetail.engineCapacityManual = true;
            break;
          case "cylinder":
            this.carDetail.cylinder = value[carKey];
            this.carAttributesParams.cylinder = value[carKey];
            this.isCylinderManual = true;
            this.carDetail.cylinderManual = true;
            break;
        }
      }
    },

    cancelFunc(){
      if(this.dealerId){
        this.$router.push(`/admin/car-dealer/${this.dealerId}`);
      } else {
        this.$router.push(`/admin/vehicle/pending`);
      }
    },

    fetchMakeOptions(search, loading) {
      if(this.timeout){
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarMakers(searchField);
      },500)
    },

    fetchModelOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarModels(searchField);
      },500)
    },

    fetchYearOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarYears(searchField);
      },500)
    },
    
    fetchBadgeOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarBadges(searchField);
      },500)
    },

    fetchSeriesOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarSeries(searchField);
      },500)
    },

    fetchBodyTypeOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarBodyType(searchField);
      },500)
    },

    fetchTransmissionOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarTransmission(searchField);
      },500)
    },

    fetchEngineCapacityOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarEngineCapacity(searchField);
      },500)
    },

    fetchCylinderOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarCylinders(searchField);
      },500)
    },

    getStates(){
      let self = this;
      this.statesList(self.carAttributesParams).then((res) => {
        self.states = res.data.data.docs;
      })
    },
    getCarMakers(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.limit = 200;
      this.carMakerList(self.carAttributesParams).then((res) => {
        self.makers = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
      })
    },

    getCarModels(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carModels(self.carAttributesParams).then((res) => {
        self.models = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
      })
    },

    getCarYears(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carYearList(self.carAttributesParams).then((res) => {
        self.years = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.years.length === 0) this.getCarBadges();
      })
    },

    getCarBadges(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carBadgeList(self.carAttributesParams).then((res) => {
        self.badges = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.badges.length === 0) this.getCarSeries();
      })
    },
    
    getCarSeries(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carSeriesList(self.carAttributesParams).then((res) => {
        self.seriesList = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.seriesList.length === 0) this.getCarBodyType();
      })
      
    },

    getCarBodyType(search=""){
      let self = this;
      self.carAttributesParams.search = search;
       self.carAttributesParams.isBodyTypeManual = self.isCarFieldManual ? true : false;
      // self.carDetail.bodyTypeManual = self.isCarFieldManual ? true : false;
      this.carBodyTypeList(self.carAttributesParams).then((res) => {
        self.bodyTypes = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.bodyTypes.length === 0) this.getCarTransmission();
      })
    },

    getCarTransmission(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isTransmissionManual = self.isCarFieldManual ? true : false;
      // self.carDetail.transmissionManual = self.isCarFieldManual ? true : false;
      this.carTransmissionList(self.carAttributesParams).then((res) => {
        self.transmissions = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = search;
        if(self.transmissions.length === 0) this.getCarEngineCapacity();
      })
    },

    getCarEngineCapacity(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isEngineCapacityManual = self.isCarFieldManual ? true : false;
      // self.carDetail.engineCapacityManual = self.isCarFieldManual ? true : false;
      this.carEngineCapacity(self.carAttributesParams).then((res) => {
        self.engineCapacities = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.engineCapacities.length === 0) this.getCarCylinders();
      })
    },
    
    getCarCylinders(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isCylinderManual = self.isCarFieldManual ? true : false;
      // self.carDetail.cylinderManual = self.isCarFieldManual ? true : false;
      this.carCylinders(self.carAttributesParams).then((res) => {
        self.cylinders = res.data.data.buckets;
        self.carAttributesParams.search = "";
        // self.carAttributesParams.after = res.data.data.after_key;
      })
    },

    getKilometerRange(){
      let self = this;
      this.kilometerRange(self.carAttributesParams).then((res) => {
        self.kilometers = res.data.data;
      })
    },

    getPriceRange(){
      let self = this;
      this.priceRange(self.carAttributesParams).then((res) => {
        self.prices = res.data.data;
      })
    },

    uploadImageSuccess(formData, index, fileList,) {
      this.imageUploadError = "";
      this.photos.push(fileList[index]);
    },

    beforeRemove(index, done, fileList) {
      this.photos.splice(index,1)
      done();
    },

    removeImageFromOldPhotos(photo, index){
      this.oldPhotos.splice(index,1)
      this.imageComponentKey++
    },
    async uploadImage(carImage){
      return await axios
        .post(
        process.env.VUE_APP_API_URL + "admin/vehicle/uploadImage",
        {carImage})
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
        console.log("image upload error", err);
        });
    },
    getCarDetail(id) {
      let self = this;
      this.carDetails(id).then((res) => {
        self.carDetail = res.data.data;
        self.oldPhotos = [ ...res.data.data.images];
        self.carDetail.isRegistrationUptoDate = (self.carDetail.isRegistrationUptoDate === "Yes") ? true : false;
        self.carDetail.hasWrittenOff = (self.carDetail.hasWrittenOff === "Yes") ? true : false;
        self.carDetail.fullServiceAvailable = (self.carDetail.fullServiceAvailable === "Yes") ? true : false;
      });
    },

    async updateCarDetail() {
      this.carDetail.dealerId = this.dealerId;
      this.carDetail.images = [];
      if((this.photos.length < 1) && (this.oldPhotos.length < 1)){
        this.imageUploadError = "At least one car image needs to be uploaded.";
      }
      
      this.carDetail.images = [...this.oldPhotos, ...this.carDetail.images]

      this.$validator.validateAll().then(async(result) => {
        let isFormValid = true;

        if(this.isCarFieldManual) {
          this.manualBodyTypeError = !this.carDetail.bodyType ? "Please enter this field": "";
          this.manualTransmissionError = !this.carDetail.transmission ? "Please enter this field": "";
          this.manualEngineCapacityError = !this.carDetail.engineCapacity ? "Please enter this field": "";
          this.manualCylinderError = !this.carDetail.cylinder ? "Please enter this field": "";
        }

        if (this.manualBodyTypeError || this.manualTransmissionError || this.manualEngineCapacityError || this.manualCylinderError) {
          isFormValid = false;
        }
        console.log('form valid', isFormValid)
        if(this.validateForm && isFormValid && (this.photos.length > 0)){
          this.$vs.loading();
          for( let photo of this.photos ) {
            let uploadImage = await this.uploadImage(photo.path);
            if(uploadImage && uploadImage.Location){
              this.carDetail.images.push({imageUrl:uploadImage.Location});
              this.imageUploadError = "";
            } else {
              this.$vs.loading.close();
              this.imageUploadError = "Image upload failed, try again";
            }
          }
        }
        if (this.imageUploadError) {
          isFormValid = false;
        }
        if (result && isFormValid && this.validateForm) {
          this.carDetail.isRegistrationUptoDate = this.carDetail.isRegistrationUptoDate ? "Yes" : "No";
          this.carDetail.hasWrittenOff = this.carDetail.hasWrittenOff ? "Yes" : "No";
          this.carDetail.fullServiceAvailable = this.carDetail.fullServiceAvailable ? "Yes" : "No";

          this.$vs.loading();
          this.updateDealerCar(this.carDetail).then((res) => {
            this.$vs.loading.close();
            if (res.data.data) {
              this.$vs.notify({
                title: "Car Updated",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              if (this.dealerId){
                this.$router.push(`/admin/car-dealer/${this.dealerId}`);
              }else{
                this.$router.push(`/admin/vehicle/pending/`);
              }
              
              // this.getCarDetail(res.data.data._id);
            } else {
              //for email already i use
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },
  },
  watch: {
    "carDetailMake": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        // this.getCarMakers();
        if(!this.isCarFieldManual) {
          this.carAttributesParams.make = newVal;
          this.carAttributesParams.model = "";
          this.carAttributesParams.year = "";
          this.carAttributesParams.badge = "";
          this.carAttributesParams.bodyType = "";
          this.carAttributesParams.series = "";
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.make = newVal;
          this.carDetail.model = "",
          this.carDetail.year = "",
          this.carDetail.badge = "",
          this.carDetail.series = "",
          this.models = [];
          this.years = [];
          this.badges = [];
          this.seriesList = [];
          this.getCarModels();
          this.getCarYears();
          this.getCarBadges();
          this.getCarSeries();
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.carAttributesParams.make = newVal;
          this.carAttributesParams.model = "";
          this.carAttributesParams.year = "";
          this.carAttributesParams.badge = "";
          this.carAttributesParams.series = "";
          this.models = [];
          this.years = [];
          this.badges = [];
          this.seriesList = [];
          this.carDetail.model = "",
          this.carDetail.year = "",
          this.carDetail.badge = "",
          this.carDetail.series = "",
          this.carDetail.modelManual = false;
          this.carDetail.yearManual = false;
          this.carDetail.seriesManual = false;
          this.carDetail.badgeManual = false;
          this.getCarModels();
          this.getCarYears();
          this.getCarSeries();
          this.getCarBadges();
        }
      } else {
        this.carAttributesParams.make = newVal;
        this.getCarModels();
      }
    },
    "carDetailModel": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.model = newVal;
          this.carDetail.model = newVal;
          this.carAttributesParams.year = "";
          this.carAttributesParams.badge = "";
          this.carAttributesParams.bodyType = "";
          this.carAttributesParams.series = "";
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.year = "",
          this.carDetail.badge = "",
          this.carDetail.series = "",
          this.years = [];
          this.badges = [];
          this.seriesList = [];
          this.getCarYears();
          this.getCarBadges();
          this.getCarSeries();
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else{
          this.carAttributesParams.model = newVal;
          this.carAttributesParams.year = "";
          this.carAttributesParams.badge = "";
          this.carAttributesParams.series = "";
          this.years = [];
          this.badges = [];
          this.seriesList = [];
          this.carDetail.year = "",
          this.carDetail.badge = "",
          this.carDetail.series = "",
          this.carDetail.yearManual = false;
          this.carDetail.seriesManual = false;
          this.carDetail.badgeManual = false;
          this.getCarYears();
          this.getCarSeries();
          this.getCarBadges();
        }
      } else{
        this.carAttributesParams.model = newVal;
        this.getCarYears();
      }
    },
    "carDetailYear": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.year = newVal;
          this.carDetail.year = newVal;
          this.carAttributesParams.badge = "";
          this.carAttributesParams.bodyType = "";
          this.carAttributesParams.series = "";
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.badge = "",
          this.carDetail.series = "",
          this.badges = [];
          this.seriesList = [];
          this.getCarBadges();
          this.getCarSeries();
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.carAttributesParams.year = newVal;
          this.carAttributesParams.badge = "";
          this.carAttributesParams.series = "";
          this.badges = [];
          this.seriesList = [];
          this.carDetail.badge = "",
          this.carDetail.series = "",
          this.carDetail.seriesManual = false;
          this.carDetail.badgeManual = false;
          this.getCarSeries();
          this.getCarBadges();
        }
      } else {
        this.carAttributesParams.year = newVal;
        this.getCarBadges();
      }
    },
    "carDetailBadge": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.badge = newVal;
          this.carDetail.badge = newVal;
          this.carAttributesParams.bodyType = "";
          this.carAttributesParams.series = "";
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.series = "",
          this.seriesList = [];
          this.getCarSeries();
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.carAttributesParams.badge = newVal;
          this.carAttributesParams.series = "";
          this.seriesList = [];
          this.carDetail.badge = "",
          this.carDetail.badgeManual = false;
          this.getCarSeries();
        }
      } else {
        this.carAttributesParams.badge = newVal;
        this.getCarSeries();
      }
    },
    "carDetailSeries": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.series = newVal;
          this.carDetail.series = newVal;
          this.carAttributesParams.bodyType = "";
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.carAttributesParams.series = newVal;
          this.carDetail.bodyTypeManual = false;
          this.getCarBodyType();
        }
      } else {
        this.carAttributesParams.series = newVal;
        this.getCarBodyType();
      }
    },
    "carDetailBodyType": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.bodyType = newVal;
          this.carDetail.bodyType = newVal;
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.transmission = "",
          this.carDetail.engineCapacity = "",
          this.carDetail.cylinder = "",
          this.transmissions = [];
          this.engineCapacities = [];
          this.cylinders = [];
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.carDetail.bodyTypeManual = true;
        }
        
      } else {
        this.carAttributesParams.bodyType = newVal;
        this.getCarTransmission();
      }
    },
    "carDetailTransmission": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.transmission = newVal;
          this.carDetail.transmission = newVal;
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.engineCapacity = "",
          this.carDetail.cylinder = "",
          this.engineCapacities = [];
          this.cylinders = [];
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.carDetail.transmissionManual = true;
        }
      } else {
        this.carAttributesParams.transmission = newVal;
        this.getCarEngineCapacity();
      }
    },
    "carDetailEngineCapacity": function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !=="") {
        if(!this.isCarFieldManual) {
          this.carAttributesParams.engineCapacity = newVal;
          this.carDetail.engineCapacity = newVal;
          this.carAttributesParams.cylinder = "";
          this.carDetail.cylinder = "",
          this.cylinders = [];
          this.getCarCylinders();
        } else {
          this.carDetail.engineCapacityManual = true;
        }
      } else {
        this.carAttributesParams.engineCapacity = newVal;
        this.getCarCylinders();
      }
    },

    "carDetailCylinder": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if(!this.isCarFieldManual) {
          this.carDetail.cylinder = newVal;
        } else {
          this.carDetail.cylinderManual = true;
        }
      }
    },

    "isCarFieldManual": function (newVal, oldVal) {
      if (newVal !== oldVal) {  
        if(this.isCarFieldManual) {
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        } else {
          this.bodyTypes = [];
          this.cylinders = [];
          this.engineCapacities = [];
          this.transmissions = [];
          this.carAttributesParams.isBodyTypeManual = false;
          this.carAttributesParams.isTransmissionManual = false;
          this.carAttributesParams.isEngineCapacityManual = false;
          this.carAttributesParams.isCylinderManual = false;
          this.carDetail.bodyTypeManual = false;
          this.carDetail.rransmissionManual = false;
          this.carDetail.engineCapacityManual = false;
          this.carDetail.cylinderManual = false;
        }
      }
    },
    
  },
  created() {
    this.dealerId = this.$route.params.id;
    this.getCarMakers();
    this.getStates();
    // this.getKilometerRange();
    // this.getPriceRange();
    this.carId = this.$route.params.carId;
    if (this.carId) {
      this.getCarDetail(this.carId);
    }
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
.fileContainer{
  position: relative;
  cursor: context-menu; 
  margin-bottom: 10px;
}
.imageContainer{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
}
.image img{
  width: 100%;
  align-self: center;
}
.btn{
 position: absolute;
  top: 10%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 16px;
  padding: 12px 24px;
  border: "none";
  cursor: pointer;
  z-index: 1000;
}
.btn:hover{
  color: red;
}
</style>  